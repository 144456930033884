<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Relatório de Estoque</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        v-show="$vuetify.breakpoint.smAndUp"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />

                    <v-spacer/>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="primary"
                                :loading="btnExportar"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="gerarPdf">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <span>PDF</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="gerarXls">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-file-excel
                                    </v-icon>
                                    <span>Xls</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer/>

                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="170"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <v-spacer/>
                                Período
                                <v-spacer/>
                            </v-card-title>

                            <v-divider/>

                            <v-card-text>
                                <v-row class="mt-4">
                                    <v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data inicial"
                                            v-model="form.start"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm"
                                            okText="Confirmar"
                                            clearText="Cancelar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': true,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>
                                    <v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data final"
                                            v-model="form.end"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm"
                                            okText="Confirmar"
                                            clearText="Cancelar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': true,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="form.order"
                                            label="Ordenar por"
                                            :items="order"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox
                                            v-model="form.group"
                                            label="Agrupar por categoria do produto"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn color="gray" text @click="menu = false">Cancelar</v-btn>
                                <v-spacer/>
                                <v-btn color="primary" @click="consultar" class="ml-1">
                                    <v-icon>mdi-magnify</v-icon>&nbsp;Consultar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :items="dados"
                            :search="search"
                            :headers="headers"
                            :loading="loading"
                            :mobileBreakpoint="0"
                            :height="$vuetify.breakpoint.height - 180"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            @click:row="openComanda"
                        >
                            <template v-slot:item.total="{item}">
                                <span style="color: green;">
                                    R$&nbsp;{{ (+item.total).toFixed(2) }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
            <a ref="aDownload" style="display: none;"></a>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'RelatorioEstoque',

    components: {
        BaseContainer,
    },

    data: () => ({
        search: '',
        form: {
            group: false,
            order: 'quantidade',
        },
        venda: {},
        loading: false,
        btnExportar: false,
        menu: false,
        dados: [],
        order: [
            { value: 'quantidade', text: 'Quantidade' },
            { value: 'total', text: 'Total' },
            { value: 'custo', text: 'Custo' },
            { value: 'lucro', text: 'Lucro' },
        ],
        headers: [
            { text: 'Cód. Produto', value: 'id' },
            { text: 'Nome', value: 'descricao' },
            { text: 'Grupo', value: 'grupo.descricao' },
            { text: 'NCM', value: 'categoria_imposto.ncm',  align: 'center' },
            { text: 'Estoque', value: 'estoque' },
        ],
    }),

    mounted() {
        this.form.start = this.moment().startOf(this.venda.periodo).format('DD/MM/YYYY HH:mm'),
        this.form.end = this.moment().endOf(this.venda.periodo).format('DD/MM/YYYY HH:mm');

        this.consultar();
    },

    methods: {
        consultar() {
            this.dados = [];
            this.menu = false;

            // const start = this.moment(this.form.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            // const end = this.moment(this.form.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            // const params = `start=${start}&end=${end}&order=${this.form.order}&group=${this.form.group}`;

            this.loading = true;
            this.$http.get(`relatorio-estoque`).then(resp => {
                this.dados = resp.data.data;
            }).finally(() => (this.loading = false));
        },

        async gerarPdf() {
            if (this.btnExportar) {
                return;
            }

            this.btnExportar = true;
            try {
                // const start = this.moment(this.form.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                // const end = this.moment(this.form.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

                const params = new URLSearchParams();
                params.append('report', '\\App\\Reports\\EstoqueReport');
                // params.append('start', start);
                // params.append('end', end);
                // params.append('group', this.form.group);
                // params.append('order', this.form.order);

                const resp = await this.$gerarPdf(params);
                if (resp?.type === 'warning') {
                    this.notify(resp.msg, 'warning');
                }
            } finally {
                this.btnExportar = false;
            }
        },

        async gerarXls() {
            if (this.btnExportar) {
                return;
            }

        //    if (!this.form.start || !this.form.end) {
        //         this.notify('Informe o período', 'warning');
        //         return;
        //     }

            // const start = this.moment(this.form.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            // const end = this.moment(this.form.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

            const data = {
                report: '\\App\\Reports\\EstoqueReport',
                // group: this.form.group,
                // order: this.form.order,
                // start,
                // end,

            };
            const post = [];

            for (var i in data) {
                post.push(i + '=' + encodeURIComponent(data[i]));
            }
            const link = this.$refs.aDownload;
            this.btnExportar = true;

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = ({target}) => {
                if (target.readyState === 4 && target.status === 200) {
                    const blob = xhr.response;
                    const url = window.URL || window.webkitURL;

                    link.download = `Relatorio_Estoque_${this.moment().format('DD_MM_YYYY')}.xls`;
                    link.href = url.createObjectURL(blob);
                    link.click();

                    this.btnExportar = false
                }
            };
            xhr.open('POST', `${this.$urlAPI}/report/gerar-xls`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

            xhr.responseType = 'blob';

            xhr.send(post.join('&'));
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
